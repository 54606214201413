<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <ResponseAlert ref="response" />
        <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
          <div class="col-md-6 col-12 px-0 order-1 order-md-0 d-flex flex-column flex-md-row">
            <b-form-select
              v-model="vars.additional_params.kfsId"
              :options="masters.kfs"
              value-field="id"
              text-field="name"
              class="col-lg-4 col-md-3 mb-1 mb-md-0 mr-0 mr-md-1"
            />
            <div
              class="input-group col-lg-7 col-md-8 px-0"
            >
              <input
                v-model="config.search"
                type="text"
                placeholder="Cari nama customer"
                class="form-control"
                @keyup.enter="config.page = 1; get()"
              >
              <div class="input-group-append">
                <div
                  class="input-group-text"
                  @click="config.page = 1; get()"
                >
                  <feather-icon icon="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
          <div class="order-0 order-md-1 mb-1 mb-md-0">
            <b-button
              v-if="hasPermission('filter/customer')"
              v-b-modal.modal-filter-customer
              variant="secondary"
              class="btn-min-width rounded mr-1 full-width-responsive mb-1 mb-md-0"
            >
              Advance Filter
            </b-button>
            <custom-button
              text="Add Customer"
              :url="'/'+ config.uri+'/add'"
              permission="add/customer"
              class-name="btn btn-primary display-table-cell pull-right rounded full-width-responsive"
            />
          </div>
        </div>
        <b-col sm="12">
          <div class="table-responsive">
            <table class="table b-table">
              <thead>
                <tr>
                  <th
                    v-for="(row, key) in config.headers"
                    :key="key"
                    :width="row.width"
                    :class="row.class"
                  >
                    <a
                      v-if="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                      @click="sort(row.value)"
                    >
                      {{ row.title }}
                      <i
                        :id="row.value"
                        class="fa fa-sort"
                      />
                    </a>
                    <a
                      v-else
                      :id="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                    >
                      {{ row.title }}
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="config.total_data">
                  <tr
                    v-for="(row, key) in config.rows"
                    :key="key"
                  >
                    <td>{{ row.profile.fullName }}</td>
                    <td>{{ row.profile.phone }}</td>
                    <td>{{ row.email }}</td>
                    <td>{{ row.uniqueId }}</td>
                    <td>{{ row.profile.createdAt ? moment(row.profile.createdAt).format('DD/MM/YYYY') : '-' }}</td>
                    <td>{{ row.lastVisited ? moment(row.lastVisited).format('DD/MM/YYYY') : '-' }}</td>
                    <td>{{ row.profile.since }}</td>
                    <td align="center">
                      <div class="d-flex">
                        <custom-button
                          permission="detail/customer"
                          :url="`/${config.uri}/${row.id}`"
                          title="Detail"
                          class-name="btn-action"
                        >
                          <feather-icon icon="EyeIcon" />
                        </custom-button>
                        <custom-button
                          permission="edit/customer"
                          :url="`/${config.uri}/edit/${row.id}`"
                          title="Edit"
                          class-name="btn-action px-1"
                        >
                          <feather-icon icon="EditIcon" />
                        </custom-button>
                        <custom-button
                          type="button"
                          permission="delete/customer"
                          title="Delete"
                          class-name="btn-action bg-transparent border-0 text-primary p-0"
                          @click="destroy(row.id)"
                        >
                          <feather-icon icon="TrashIcon" />
                        </custom-button>
                      </div>
                    </td>
                  </tr>
                </template>
                <tr v-if="!config.total_data">
                  <td
                    :colspan="config.headers.length"
                    align="center"
                  >
                    No data available.
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="config.total_data">
                <tr>
                  <td
                    :colspan="config.headers.length"
                    class="p-0"
                  >
                    <div class="d-flex justify-content-between mt-2">
                      <b-form-select
                        v-model="config.per_page"
                        :options="perPageOptions"
                        class="w-auto"
                      />
                      <b-pagination
                        v-model="config.page"
                        :total-rows="config.total_data"
                        :per-page="config.per_page"
                        @change="gotoPage"
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal Advance Search -->
    <b-modal
      id="modal-filter-customer"
      ref="modal-filter-customer"
      centered
      title="Filter"
      hide-footer
    >
      <form
        @submit.prevent="advanceSearch"
      >
        <div class="animated fadeIn">
          <b-row
            align-v="baseline"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="tag"
              >KFS</label>
            </b-col>
            <b-col sm="7">
              <b-form-select
                id="tag"
                v-model="models.kfsId"
                value-field="id"
                text-field="name"
                :options="masters.kfs"
                class="w-100"
              />
            </b-col>
          </b-row>
          <b-row
            class="my-1"
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="tag"
              >Tanggal Registrasi</label>
            </b-col>
            <b-col sm="7">
              <date-range-picker
                :date-range="vars.registrationDate"
                @update="value => updateDate('createdAt', value)"
              />
            </b-col>
          </b-row>
          <b-row
            align-v="center"
          >
            <b-col sm="5">
              <label
                class="h5"
                for="tag"
              >Tanggal Kunjungan</label>
            </b-col>
            <b-col sm="7">
              <date-range-picker
                :date-range="vars.visitedDate"
                @update="value => updateDate('lastVisited', value)"
              />
            </b-col>
          </b-row>
          <div class="d-flex flex-column flex-md-row justify-content-center justify-content-md-end mt-2">

            <b-button
              type="button"
              variant="outline-secondary"
              class="btn-min-width rounded full-width-responsive"
              @click="exportExcel"
            >
              Export Data
            </b-button>
            <b-button
              type="button"
              variant="outline-primary"
              class="btn-min-width rounded mx-md-1 my-1 my-md-0 full-width-responsive"
              @click="advanceSearch('reset')"
            >
              Reset
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded full-width-responsive"
            >
              Simpan
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BPagination, BFormSelect, BButton,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'
import { exportExcel } from '@/utils/helpers'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'

export default {
  name: 'ListCustomers',
  metaInfo: {
    title: 'List Customers',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
    BButton,
    DateRangePicker,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.customers,
        rows: [],
        additional_params: {
          kfsId: null,
          createdAt: null,
          lastVisited: null,
        },
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama',
          value: 'profile.firstName',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'No. Telepon',
          value: 'profile.phone',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Email',
          value: 'email',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Member',
          value: 'uniqueId',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Tanggal Regis',
          value: 'profile.createdAt',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Kunjungan Terakhir',
          value: '',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Tags',
          value: 'profile.since',
          align: 'text-left',
          width: '10%',
        }, {
          title: 'Action',
          value: '',
          class: 'text-center',
          width: '10%',
        }],
      },
      masters: {
        kfs: [
          {
            id: null,
            name: '- Pilih KFS -',
          },
        ],
      },
      models: {
        kfsId: null,
        createdAt: null,
        lastVisited: null,
      },
      vars: {
        additional_params: {
          kfsId: null,
        },
        registrationDate: {
          startDate: null,
          endDate: null,
        },
        visitedDate: {
          startDate: null,
          endDate: null,
        },
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    kfsId() {
      return this.vars.additional_params.kfsId
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
    kfsId() {
      this.config.additional_params.kfsId = this.kfsId
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
    _.getOptions()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.customers}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
    getOptions() {
      this.$axios.get(api.list_kfs).then(res => {
        this.masters.kfs = [...this.masters.kfs, ...res.data.data]
      })
    },
    advanceSearch(value) {
      if (value === 'reset') {
        this.config.additional_params.kfsId = this.config.additional_params.createdAt = this.config.additional_params.lastVisited = this.models.kfsId = this.models.createdAt = this.models.lastVisited = this.vars.additional_params.kfsId = this.vars.registrationDate.startDate = this.vars.registrationDate.endDate = this.vars.visitedDate.startDate = this.vars.visitedDate.endDate = null
      } else {
        this.config.additional_params.kfsId = this.vars.additional_params.kfsId = this.models.kfsId
        this.config.additional_params.createdAt = this.models.createdAt
        this.config.additional_params.lastVisited = this.models.lastVisited
      }
      this.config.page = 1
      if (!this.models.kfsId) {
        this.get()
      }
      this.$bvModal.hide('modal-filter-customer')
    },

    updateDate(type, value) {
      if (type === 'createdAt') {
        this.vars.registrationDate = value
      } else {
        this.vars.visitedDate = value
      }
      const { startDate, endDate } = value
      this.models[type] = `betweenDate:${this.moment(startDate).format('YYYY-MM-DD')},${this.moment(endDate).format('YYYY-MM-DD')}`
    },

    exportExcel() {
      exportExcel('List Customers', `${api.customers}/export`, this.models)
      this.$bvModal.hide('modal-filter-customer')
    },
  },
}
</script>
